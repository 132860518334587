<template>
  <div class="w-block__post-toolbar-1">
    <v-container v-if="$route.name === 'index'" class="pa-4">
      <v-sheet color="#FCE6B9" dark class="pa-3">
        <v-flex xs12>
          <div class="custom-color">
            Apm Audio, toutes les voix de l’Apm !
            <v-btn
              ref="btn"
              color="#FF6E76"
              rounded
              depressed
              class="white--text"
              @click.stop="handleOpen()"
            >
              <v-icon color="#ffffff" class="mr-2"> mdi-volume-high </v-icon>
              Découvrez le Teaser
            </v-btn>
          </div>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false, 
    }
  },
  methods: {
    handleOpen(e) {
      this.$spoke
        .collection('contents')
        .getOne('661e975f156b4511d9b97a54')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__post-toolbar-1 {
  justify-content: center;
  text-align: center;
}
.v-btn__content {
  background-color: transparent;
}
.v-btn::before {
  background-color: transparent;
}
.v-card {
  border-radius: 8px;
}
.v-sheet {
  border-radius: 8px;
}
.custom-color {
  color: #ff6e76;
}
.v-btn {
  border-radius: 4px;
}
</style>
-->
